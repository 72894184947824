import { ConsoleApiName } from '../../tools/display';
import { NO_ERROR_STACK_PRESENT_MESSAGE, isError } from '../error/error';
import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
import { getExperimentalFeatures } from '../../tools/experimentalFeatures';
import { INTAKE_SITE_STAGING, INTAKE_SITE_US1_FED } from '../configuration';
import { Observable } from '../../tools/observable';
import { timeStampNow } from '../../tools/utils/timeUtils';
import { displayIfDebugEnabled, startMonitorErrorCollection } from '../../tools/monitor';
import { sendToExtension } from '../../tools/sendToExtension';
import { performDraw } from '../../tools/utils/numberUtils';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { combine } from '../../tools/mergeInto';
import { computeStackTrace } from '../../tools/stackTrace/computeStackTrace';
import { getConnectivity } from '../connectivity';
import { createBoundedBuffer } from '../../tools/boundedBuffer';
import { TelemetryType } from './rawTelemetryEvent.types';
const ALLOWED_FRAME_URLS = ['https://www.datadoghq-browser-agent.com', 'https://www.datad0g-browser-agent.com', 'https://d3uc069fcn7uxw.cloudfront.net', 'https://d20xtzwzcl0ceb.cloudfront.net', 'http://localhost', '<anonymous>'];
const TELEMETRY_EXCLUDED_SITES = [INTAKE_SITE_US1_FED];
// eslint-disable-next-line local-rules/disallow-side-effects
let preStartTelemetryBuffer = createBoundedBuffer();
let onRawTelemetryEventCollected = event => {
  preStartTelemetryBuffer.add(() => onRawTelemetryEventCollected(event));
};
export function startTelemetry(telemetryService, configuration) {
  let contextProvider;
  const observable = new Observable();
  const alreadySentEvents = new Set();
  const telemetryEnabled = !TELEMETRY_EXCLUDED_SITES.includes(configuration.site) && performDraw(configuration.telemetrySampleRate);
  const telemetryEnabledPerType = {
    [TelemetryType.log]: telemetryEnabled,
    [TelemetryType.configuration]: telemetryEnabled && performDraw(configuration.telemetryConfigurationSampleRate),
    [TelemetryType.usage]: telemetryEnabled && performDraw(configuration.telemetryUsageSampleRate)
  };
  const runtimeEnvInfo = getRuntimeEnvInfo();
  onRawTelemetryEventCollected = rawEvent => {
    const stringifiedEvent = jsonStringify(rawEvent);
    if (telemetryEnabledPerType[rawEvent.type] && alreadySentEvents.size < configuration.maxTelemetryEventsPerPage && !alreadySentEvents.has(stringifiedEvent)) {
      const event = toTelemetryEvent(telemetryService, rawEvent, runtimeEnvInfo);
      observable.notify(event);
      sendToExtension('telemetry', event);
      alreadySentEvents.add(stringifiedEvent);
    }
  };
  startMonitorErrorCollection(addTelemetryError);
  function toTelemetryEvent(telemetryService, event, runtimeEnvInfo) {
    return combine({
      type: 'telemetry',
      date: timeStampNow(),
      service: telemetryService,
      version: "6.5.0",
      source: 'browser',
      _dd: {
        format_version: 2
      },
      telemetry: combine(event, {
        runtime_env: runtimeEnvInfo,
        connectivity: getConnectivity(),
        sdk_setup: "npm"
      }),
      experimental_features: Array.from(getExperimentalFeatures())
    }, contextProvider !== undefined ? contextProvider() : {});
  }
  return {
    setContextProvider: provider => {
      contextProvider = provider;
    },
    observable,
    enabled: telemetryEnabled
  };
}
function getRuntimeEnvInfo() {
  return {
    is_local_file: window.location.protocol === 'file:',
    is_worker: 'WorkerGlobalScope' in self
  };
}
export function startFakeTelemetry() {
  const events = [];
  onRawTelemetryEventCollected = event => {
    events.push(event);
  };
  return events;
}
// need to be called after telemetry context is provided and observers are registered
export function drainPreStartTelemetry() {
  preStartTelemetryBuffer.drain();
}
export function resetTelemetry() {
  preStartTelemetryBuffer = createBoundedBuffer();
  onRawTelemetryEventCollected = event => {
    preStartTelemetryBuffer.add(() => onRawTelemetryEventCollected(event));
  };
}
/**
 * Avoid mixing telemetry events from different data centers
 * but keep replicating staging events for reliability
 */
export function isTelemetryReplicationAllowed(configuration) {
  return configuration.site === INTAKE_SITE_STAGING;
}
export function addTelemetryDebug(message, context) {
  displayIfDebugEnabled(ConsoleApiName.debug, message, context);
  onRawTelemetryEventCollected({
    type: TelemetryType.log,
    message,
    status: "debug" /* StatusType.debug */,
    ...context
  });
}
export function addTelemetryError(e, context) {
  onRawTelemetryEventCollected({
    type: TelemetryType.log,
    status: "error" /* StatusType.error */,
    ...formatError(e),
    ...context
  });
}
export function addTelemetryConfiguration(configuration) {
  onRawTelemetryEventCollected({
    type: TelemetryType.configuration,
    configuration
  });
}
export function addTelemetryUsage(usage) {
  onRawTelemetryEventCollected({
    type: TelemetryType.usage,
    usage
  });
}
export function formatError(e) {
  if (isError(e)) {
    const stackTrace = computeStackTrace(e);
    return {
      error: {
        kind: stackTrace.name,
        stack: toStackTraceString(scrubCustomerFrames(stackTrace))
      },
      message: stackTrace.message
    };
  }
  return {
    error: {
      stack: NO_ERROR_STACK_PRESENT_MESSAGE
    },
    message: `${"Uncaught" /* NonErrorPrefix.UNCAUGHT */} ${jsonStringify(e)}`
  };
}
export function scrubCustomerFrames(stackTrace) {
  stackTrace.stack = stackTrace.stack.filter(frame => !frame.url || ALLOWED_FRAME_URLS.some(allowedFrameUrl => frame.url.startsWith(allowedFrameUrl)));
  return stackTrace;
}
