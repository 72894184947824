import { timeStampNow } from '../../tools/utils/timeUtils';
import { normalizeUrl } from '../../tools/utils/urlPolyfill';
import { generateUUID } from '../../tools/utils/stringUtils';
import { INTAKE_SITE_US1, INTAKE_SITE_FED_STAGING, PCI_INTAKE_HOST_US1 } from './intakeSites';
export function createEndpointBuilder(initConfiguration, trackType, configurationTags) {
  const buildUrlWithParameters = createEndpointUrlWithParametersBuilder(initConfiguration, trackType);
  return {
    build(api, payload) {
      const parameters = buildEndpointParameters(initConfiguration, trackType, configurationTags, api, payload);
      return buildUrlWithParameters(parameters);
    },
    urlPrefix: buildUrlWithParameters(''),
    trackType
  };
}
/**
 * Create a function used to build a full endpoint url from provided parameters. The goal of this
 * function is to pre-compute some parts of the URL to avoid re-computing everything on every
 * request, as only parameters are changing.
 */
function createEndpointUrlWithParametersBuilder(initConfiguration, trackType) {
  const path = `/api/v2/${trackType}`;
  const proxy = initConfiguration.proxy;
  if (typeof proxy === 'string') {
    const normalizedProxyUrl = normalizeUrl(proxy);
    return parameters => `${normalizedProxyUrl}?ddforward=${encodeURIComponent(`${path}?${parameters}`)}`;
  }
  if (typeof proxy === 'function') {
    return parameters => proxy({
      path,
      parameters
    });
  }
  const host = buildEndpointHost(trackType, initConfiguration);
  return parameters => `https://${host}${path}?${parameters}`;
}
export function buildEndpointHost(trackType, initConfiguration) {
  const {
    site = INTAKE_SITE_US1,
    internalAnalyticsSubdomain
  } = initConfiguration;
  if (trackType === 'logs' && initConfiguration.usePciIntake && site === INTAKE_SITE_US1) {
    return PCI_INTAKE_HOST_US1;
  }
  if (internalAnalyticsSubdomain && site === INTAKE_SITE_US1) {
    return `${internalAnalyticsSubdomain}.${INTAKE_SITE_US1}`;
  }
  if (site === INTAKE_SITE_FED_STAGING) {
    return `http-intake.logs.${site}`;
  }
  const domainParts = site.split('.');
  const extension = domainParts.pop();
  return `browser-intake-${domainParts.join('-')}.${extension}`;
}
/**
 * Build parameters to be used for an intake request. Parameters should be re-built for each
 * request, as they change randomly.
 */
function buildEndpointParameters({
  clientToken,
  internalAnalyticsSubdomain
}, trackType, configurationTags, api, {
  retry,
  encoding
}) {
  const tags = [`sdk_version:${"6.5.0"}`, `api:${api}`].concat(configurationTags);
  if (retry) {
    tags.push(`retry_count:${retry.count}`, `retry_after:${retry.lastFailureStatus}`);
  }
  const parameters = ['ddsource=browser', `ddtags=${encodeURIComponent(tags.join(','))}`, `dd-api-key=${clientToken}`, `dd-evp-origin-version=${encodeURIComponent("6.5.0")}`, 'dd-evp-origin=browser', `dd-request-id=${generateUUID()}`];
  if (encoding) {
    parameters.push(`dd-evp-encoding=${encoding}`);
  }
  if (trackType === 'rum') {
    parameters.push(`batch_time=${timeStampNow()}`);
  }
  if (internalAnalyticsSubdomain) {
    parameters.reverse();
  }
  return parameters.join('&');
}
