import { isEmptyObject } from '../../tools/utils/objectUtils';
import { objectEntries } from '../../tools/utils/polyfills';
import { dateNow } from '../../tools/utils/timeUtils';
import { generateUUID } from '../../tools/utils/stringUtils';
import { SESSION_EXPIRATION_DELAY, SESSION_TIME_OUT_DELAY } from './sessionConstants';
import { isValidSessionString, SESSION_ENTRY_REGEXP, SESSION_ENTRY_SEPARATOR } from './sessionStateValidation';
export const EXPIRED = '1';
export function getExpiredSessionState(previousSessionState, configuration) {
  const expiredSessionState = {
    isExpired: EXPIRED
  };
  if (configuration.trackAnonymousUser) {
    if (previousSessionState === null || previousSessionState === void 0 ? void 0 : previousSessionState.anonymousId) {
      expiredSessionState.anonymousId = previousSessionState === null || previousSessionState === void 0 ? void 0 : previousSessionState.anonymousId;
    } else {
      expiredSessionState.anonymousId = generateUUID();
    }
  }
  return expiredSessionState;
}
export function isSessionInNotStartedState(session) {
  return isEmptyObject(session);
}
export function isSessionStarted(session) {
  return !isSessionInNotStartedState(session);
}
export function isSessionInExpiredState(session) {
  return session.isExpired !== undefined || !isActiveSession(session);
}
// An active session is a session in either `Tracked` or `NotTracked` state
function isActiveSession(sessionState) {
  // created and expire can be undefined for versions which was not storing them
  // these checks could be removed when older versions will not be available/live anymore
  return (sessionState.created === undefined || dateNow() - Number(sessionState.created) < SESSION_TIME_OUT_DELAY) && (sessionState.expire === undefined || dateNow() < Number(sessionState.expire));
}
export function expandSessionState(session) {
  session.expire = String(dateNow() + SESSION_EXPIRATION_DELAY);
}
export function toSessionString(session) {
  return objectEntries(session)
  // we use `aid` as a key for anonymousId
  .map(([key, value]) => key === 'anonymousId' ? `aid=${value}` : `${key}=${value}`).join(SESSION_ENTRY_SEPARATOR);
}
export function toSessionState(sessionString) {
  const session = {};
  if (isValidSessionString(sessionString)) {
    sessionString.split(SESSION_ENTRY_SEPARATOR).forEach(entry => {
      const matches = SESSION_ENTRY_REGEXP.exec(entry);
      if (matches !== null) {
        const [, key, value] = matches;
        if (key === 'aid') {
          // we use `aid` as a key for anonymousId
          session.anonymousId = value;
        } else {
          session[key] = value;
        }
      }
    });
  }
  return session;
}
